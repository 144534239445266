<template>
	<div>
		<div class="sunbathingList">
			<div class="sl_div">
				<div class="sl_di_div">
					<img class="sl_di_di_img" src="../../../../assets/img/s-head@icon.png" />
					<div class="sl_di_di_name">刘先生/女士</div>
				</div>
				
				<div class="sl_di_rate">
					<el-rate
					  v-model="value"
					  disabled
					  text-color="#d1102d">
					</el-rate>
				</div>
				
				<div class="sl_di_labelDiv">
					<span class="sl_di_ld_text">PCB</span>
					<span class="sl_di_ld_text">24H</span>
				</div>
				
				<div class="sl_di_text">2.1*2.45*cm,1.2mm,2层,10片</div>
				<div class="sl_di_content">
					很好，非常好，已合作几年，每次样品都很快，做得非常有效率，表面干净整洁.
				</div>
				
				<div class="sl_di_imgDiv">
					<el-image class="sl_di_id_img" :src="require('@/assets/img/sunbathing.jpg')" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				value:5
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.sunbathingList{
			padding: 20px 200px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				margin-left: 1%;
				width: 24%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.sunbathingList{
			padding: 20px 200px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				margin-left: 1%;
				width: 24%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.sunbathingList{
			padding: 20px 140px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				margin-left: 1%;
				width: 24%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.sunbathingList{
			padding: 20px 30px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				margin-left: 1%;
				width: 24%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.sunbathingList{
			padding: 20px 30px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				margin-left: 1%;
				width: 48%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.sunbathingList{
			padding: 20px 30px;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			
			.sl_div{
				padding: 20px;
				background: #fff;
				width: 100%;
				margin-bottom: 2%;
				
				.sl_di_div{
					display: flex;
					align-items: center;
					
					.sl_di_di_img{
						width: 30px;
						height: 30px;
					}
					
					.sl_di_di_name{
						margin-left: 4px;
						font-size: 14px;
					}
				}
				
				.sl_di_rate{
					padding-top: 10px;
				}
				
				.sl_di_labelDiv{
					display: flex;
					padding-top: 20px;
					
					.sl_di_ld_text{
						display: inline-block;
						height: 19px;
						background-color: #ffeadf;
						border-radius: 3px;
						padding: 0 10px;
						font-size: 12px;
						color: #d1102d;
						vertical-align: top;
						font-weight: 700;
						margin-right: 10px;
					}
				}
				
				.sl_di_text{
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					line-height: 19px;
					white-space: nowrap;
					max-width: 200px;
					margin-right: 6px;
					color: #bbbbbb;
					font-size: 14px;
					margin-top: 10px;
				}
				
				.sl_di_content{
					height: 40px;
					margin-top: 10px;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					margin-bottom: 10px;
				}
				
				.sl_di_imgDiv{
					width: 100%;
					
					.sl_di_id_img{
						height: 300px;
						width: 100%;
					}
				}
			}
		}
	}
	
</style>